<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          close-on-content-click
          transition="scale-transition"
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-select
          v-model="filter"
          :items="['OneXOX','XOX']"
          label="Filter by"
          clearable
        />
        <v-spacer />
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            icon
            @click="showChart = !showChart"
          >
            <v-icon>{{ showChart ? 'cancel' : 'mdi-chart-areaspline' }}</v-icon>
          </v-btn>
          <span>{{ showChart ? 'Hide chart' : 'Show chart' }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-slide-y-transition>
        <v-card-text v-show="showChart">
          <chart
            :options="chartOptions"
            :series="series"
            type="area"
          />
        </v-card-text>
      </v-slide-y-transition>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :total-items="31"
          class="px-1 pb-1"
          item-key="date"
          hide-actions
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr @click="props.expanded = !props.expanded">
              <td class="text-xs-right">
                {{ props.item.day }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.basicP }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.basicB }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.fullP }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.fullB }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.d225P }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.d225B }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.sld }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.total }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-center">
  &nbsp;
              </td>
              <td class="text-xs-center info--text">
                {{ totals.basicP }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.basicB }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.fullP }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.fullB }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.d225P }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.d225B }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.sld }}
              </td>
              <td class="text-xs-center info">
                {{ totals.total }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import apexcharts from '@/mixins/apexcharts'

const reportTitle = 'Daily Recruitment'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
    Chart: () => import(/* webpackChunkName: "chart" */ '@/components/Chart'),
  },
  mixins: [apexcharts],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Daily Excerpt', disabled: false, to: '/management/daily_view',
        },
        {
          text: 'Daily Recruitment', disabled: true,
        },
      ],
      expand: true,
      filter: 'All',
      headers: [
        {
          align: 'right',
          sortable: false,
          text: 'Date',
          value: 'date',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Basic Dealer Prepaid',
          value: 'basicP',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Basic Dealer Black',
          value: 'basicB',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer Prepaid',
          value: 'fullP',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer Black',
          value: 'fullB',
        },
        {
          align: 'center',
          sortable: false,
          text: 'D225 Prepaid',
          value: 'd225P',
        },
        {
          align: 'center',
          sortable: false,
          text: 'D225 Black',
          value: 'd225B',
        },
        {
          align: 'center',
          sortable: false,
          text: 'SLD',
          value: 'sld',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Total',
          value: 'total',
        },
      ],
      items: [],
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      showChart: false,
      title: reportTitle,
    }
  },
  computed: {
    series: function () {
      const series = [
        { name: 'Prepaid', data: [] },
        { name: 'Black', data: [] },
      ]

      for (let i = 0; i < this.filteredItems.length; i++) {
        const timestamp = DateTime.fromSQL(this.filteredItems[i].date).toMillis()
        series[0].data.push([timestamp, this.filteredItems[i].prepaid])
        series[1].data.push([timestamp, this.filteredItems[i].black])
      }

      return series
    },
    onexox: function () {
      return this.items.filter(item => item.onex)
    },
    xox: function () {
      return this.items.filter(item => !item.onex)
    },
    all: function () {
      const all = []
      for (let i = 0; i < this.onexox.length; i++) {
        all.push({
          date: this.onexox[i].date,
          basicP: this.onexox[i].basicP + (this.xox[i] ? this.xox[i].basicP : 0),
          basicB: this.onexox[i].basicB + (this.xox[i] ? this.xox[i].basicB : 0),
          fullP: this.onexox[i].fullP + (this.xox[i] ? this.xox[i].fullP : 0),
          fullB: this.onexox[i].fullB + (this.xox[i] ? this.xox[i].fullB : 0),
          d225P: this.onexox[i].d225P + (this.xox[i] ? this.xox[i].d225P : 0),
          d225B: this.onexox[i].d225B + (this.xox[i] ? this.xox[i].d225B : 0),
          sld: this.onexox[i].sld + (this.xox[i] ? this.xox[i].sld : 0),
          total: this.onexox[i].total + (this.xox[i] ? this.xox[i].total : 0),
        })
      }

      return all
    },
    filteredItems: function () {
      let items
      if (this.filter === 'OneXOX') items = this.onexox
      else if (this.filter === 'XOX') items = this.xox
      else items = this.all

      for (const item of items) {
        const date = new Date(DateTime.fromSQL(item.date).toMillis())
        item.day = date.getDate()
      }

      return items
    },
    totals: function () {
      const items = this.filteredItems
      const totals = {
        basicP: 0,
        basicB: 0,
        fullP: 0,
        fullB: 0,
        d225P: 0,
        d225B: 0,
        sld: 0,
        total: 0,
      }

      for (const item of items) {
        totals.basicP += item.basicP
        totals.basicB += item.basicB
        totals.fullP += item.fullP
        totals.fullB += item.fullB
        totals.d225P += item.d225P
        totals.d225B += item.d225B
        totals.sld += item.sld
        totals.total += item.total
      }

      return totals
    },
  },
  watch: {
    monthOf: function (val) {
      this.getActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2015, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getActivations (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.$rest.get('getRecruitmentByMonth.php', params)
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
